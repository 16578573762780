import React from "react"

import { useSanity } from "@hooks/useSanity"
import { useCore } from "@app/hooks/useCore"

export const withHomepage = Component => ({ name = `Homepage`, page }) => {
  const { heroNormaliser } = useSanity()
  const {
    helpers: { isDomReady },
  } = useCore()

  const content = {
    ...page,
    sections: [heroNormaliser(page?.heroBanner), ...page.content],
  }

  Component.displayName = name
  return isDomReady ? <Component {...page} content={content} /> : null
}
