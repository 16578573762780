import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

import { Homepage as Page } from "@components/Homepage/Homepage"

export const query = graphql`
  query {
    page: sanityPageHomepage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => (
  <>
    {
      process.env.GATSBY_SHOPIFY_DEFAULT_SHOP === 'onedaybridal-us' ?
        <Helmet>
          <script type="application/ld+json">
            {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Bridal & wedding dresses store | KYHA Studios | New York, US",
            "url": "https://kyhastudios.com/us/",
            "description": "At KYHA we offer an alternative to traditional. We seek to break the rules, creating effortless bridal looks for the modern day bride. Visit our store in New York.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "57 Bond Street, Noho",
              "addressLocality": "New York",
              "addressRegion": "NY",
              "postalCode": "10012",
              "addressCountry": "United States"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1 (929) 335-9293",
              "contactType": "Customer Service"
            }
          }
        `}
          </script>
        </Helmet>
        : null
    }
    <Page {...props} {...data} />
  </>
);