import React, { memo } from "react"

import { withHomepage } from "./withHomepage"
import { Sections } from "../Sections/Sections"

interface Props {
  content: any
}

export const Homepage = memo(withHomepage(({ content }: Props) => <Sections content={content.sections} />))
